import s from './Text.module.css';
import React from 'react';
import cn from 'clsx';

export const Text = ({style, className = '', variant = 'body', children, html, onClick}) => {
  const componentsMap = {
    body: 'div',
    heading: 'h1',
    pageHeading: 'h1',
    sectionHeading: 'h2'
  };

  const Component = componentsMap[variant];

  const htmlContentProps = html
    ? {
      dangerouslySetInnerHTML: {__html: html}
    }
    : {};

  return React.createElement(
    Component,
    {
      className: cn(
        s.root,
        {
          [s.body]: variant === 'body',
          [s.heading]: variant === 'heading',
          [s.pageHeading]: variant === 'pageHeading',
          [s.sectionHeading]: variant === 'sectionHeading'
        },
        className
      ),
      onClick: onClick,
      style: style,
      ...htmlContentProps
    },
    children
  );
};
